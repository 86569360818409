import React from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Chip,
  Divider as MuiDivider,
  Link,
  Paper,
  TextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { useApp } from "../../../AppProvider";
import { NavLink } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function DataEntryGroupsManagement() {
  const { doToast, currentUser } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  // 1) Fetch existing column group records
  const {
    data: listColumnGroups,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ["ColumnGroups"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-entry-groups-management/column-groups`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  // 2) Fetch list_data_columns to power the multi-select
  const { data: listColumns } = useQuery(
    ["Columns"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/data-entry-groups-management/columns`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  // 3) Define table columns
  const columns = [
    {
      title: "Name",
      field: "col_group_name",
      cellStyle: {
        width: 150,
        minWidth: 150,
      },
      headerStyle: {
        width: 150,
        minWidth: 150,
      },
      validate: (rowData) =>
        rowData.col_group_name
          ? true
          : { isValid: false, helperText: "Name is required" },
    },
    {
      title: "Notes",
      field: "col_group_notes",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 200,
        minWidth: 200,
      },
      editComponent: (props) => (
        <TextField
          value={props.value || ""}
          onChange={(e) => props.onChange(e.target.value)}
          variant="standard"
          placeholder="Enter notes"
          fullWidth
        />
      ),
    },
    {
      title: "Associated Columns",
      field: "assoc_col_ndx",
      cellStyle: {
        width: "100%",
        minWidth: "300px",
      },
      headerStyle: {
        width: "100%",
        minWidth: "300px",
      },
      // How to display when not editing
      render: (rowData) => {
        if (!rowData?.assoc_col_ndx?.length) return "None";
        return rowData.assoc_col_ndx
          .map((colId) => {
            const found = listColumns?.find((c) => c.col_ndx === colId);
            return found?.col_name ?? colId;
          })
          .join(", ");
      },
      // How to display when editing
      editComponent: ({ value = [], onChange }) => {
        const selected = listColumns?.filter((c) => value.includes(c.col_ndx));
        return (
          <Autocomplete
            multiple
            disableCloseOnSelect
            size="small"
            options={listColumns || []}
            value={selected || []}
            getOptionLabel={(option) => option.col_name || ""}
            getOptionSelected={(option, val) => option.col_ndx === val.col_ndx}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  key={option.col_ndx}
                  label={option.col_name}
                  {...getTagProps({ index })}
                  style={{ margin: "4px" }}
                />
              ))
            }
            onChange={(e, newValue) => {
              const ids = newValue.map((item) => item.col_ndx);
              onChange(ids);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select columns"
              />
            )}
          />
        );
      },
    },
  ];

  // 4) Create row
  const handleAdd = (newData) =>
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/data-entry-groups-management/column-groups`,
          {
            col_group_name: newData.col_group_name,
            col_group_notes: newData.col_group_notes,
            assoc_col_ndx: newData.assoc_col_ndx ?? [],
          },
          { headers }
        );
        await refetch();
        doToast("success", "Group was successfully created.");
      } catch (error) {
        console.error(error);
        doToast("error", "Something went wrong while creating record.");
      }
    })();

  // 5) Update row
  const handleUpdate = (newData, oldData) =>
    (async () => {
      if (!oldData) return;
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.put(
          `${process.env.REACT_APP_ENDPOINT}/api/data-entry-groups-management/column-groups/${oldData.col_group_ndx}`,
          {
            col_group_name: newData.col_group_name,
            col_group_notes: newData.col_group_notes,
            assoc_col_ndx: newData.assoc_col_ndx ?? [],
          },
          { headers }
        );
        await refetch();
        doToast("success", "Group was successfully updated.");
      } catch (error) {
        console.error(error);
        doToast("error", "Something went wrong while updating record.");
      }
    })();

  // 6) Delete row
  const handleDelete = (oldData) =>
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.delete(
          `${process.env.REACT_APP_ENDPOINT}/api/data-entry-groups-management/column-groups/${oldData.col_group_ndx}`,
          { headers }
        );
        await refetch();
        doToast("success", "Group was successfully deleted.");
      } catch (error) {
        console.error(error);
        doToast("error", "Something went wrong while deleting record.");
      }
    })();

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <React.Fragment>
      <Helmet title="Data Entry Groups Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Data Entry Groups Management
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>Data Entry Groups</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Paper style={{ padding: "8px" }}>
        <MaterialTable
          title=""
          columns={columns}
          data={listColumnGroups || []}
          editable={{
            onRowAdd: handleAdd,
            onRowUpdate: handleUpdate,
            onRowDelete: handleDelete,
          }}
          isLoading={isLoading}
          localization={{
            toolbar: { searchPlaceholder: "Search Groups" },
          }}
          options={{
            emptyRowsWhenPaging: false,
            addRowPosition: "first",
            pageSize: 10,
            pageSizeOptions: [5, 10, 30, 60],
            searchFieldAlignment: "left",
            showTitle: false,
          }}
        />
      </Paper>
    </React.Fragment>
  );
}

export default DataEntryGroupsManagement;
